<template>
  <div class="profileAccessibility">
    <div class="profileAccessibility__title">
      {{ $t('profileAccessibility.title') }}
    </div>

    <p class="profileAccessibility__body">
      {{ $t('profileAccessibility.body') }}
    </p>

    <div class="profileAccessibility__textSizeWrapper">
      <div v-bind:class="{
        'profileAccessibility__textSize': true,
        'profileAccessibility__textSize--disabled': isSaving,
      }">
        <input
          type="range"
          min="70"
          max="130"
          step="5"
          class="profileAccessibility__textSizeInput"
          v-bind:disabled="isSaving"
          v-model="selectedTextSize"
        />

        <div class="profileAccessibility__textSizeOptions">
          <div
            v-for="textSize in textSizeLabels"
            v-bind:key="textSize"
            v-bind:class="{
              'profileAccessibility__textSizeOption': true,
              'profileAccessibility__textSizeOption--active': parseInt(textSize) === parseInt(selectedTextSize),
            }"
          >
            <span>
              {{ textSize }}%
            </span>
          </div>
        </div>
      </div>
    </div>

    <p class="profileAccessibility__note">
      {{ $t('profileAccessibility.note') }}
    </p>

    <profile-saved-notification v-if="showSavedNotification" />
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import ProfileSavedNotification from '@/components/profile/ProfileSavedNotification';

export default {
  components: {
    ProfileSavedNotification,
  },

  data() {
    return {
      selectedTextSize: 100,
      textSizeLabels: [70, 80, 90, 100, 110, 120, 130],
      isSaving: false,
      showSavedNotification: false,
      debounceTimeout: null,
    };
  },

  computed: {
    ...mapState('member', ['member']),
  },

  watch: {
    selectedTextSize() {
      if (this.member.text_size !== this.selectedTextSize) {
        this.changeTextSize();
      }
    }
  },

  mounted() {
    this.selectedTextSize = this.member.text_size;
  },

  methods: {
    ...mapMutations('member', ['setTextSize']),
    ...mapActions({
      updateAccessibilitySettings: 'member/updateAccessibilitySettings',
    }),

    changeTextSize() {
      this.setTextSize(this.selectedTextSize);

      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(this.saveSize, 1000);
    },

    async saveSize() {
      const postData = { text_size: this.selectedTextSize };

      this.isSaving = true;
      this.$store.commit('track/textSizeChange', this.selectedTextSize);

      this.updateAccessibilitySettings(postData)
        .then(() => {
          this.showSavedNotification = true;
        })
        .finally(() => {
          this.isSaving = false;

          setTimeout(() => {
            this.showSavedNotification = false;
          }, 2000);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.profileAccessibility__title {
  @include heading-5;
  margin: 0 0 rem(24px) 0;
}

.profileAccessibility__body {
  @include small;
  margin: 0 0 rem(24px) 0;
  max-width: 44ch;
}

.profileAccessibility__textSizeWrapper {
  margin: 0 0 rem(16px) 0;
  padding: rem(10px) rem(20px);
  border-radius: 6px;
  background: rgba($color-beige, 0.5);
}

.profileAccessibility__textSize {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 30px 0 16px 0;
  max-width: 500px;

  &--disabled {
    opacity: 0.5;
  }

  &:before,
  &:after {
    content: "A";
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: $color-text;
    z-index: 0;
  }

  &:before {
    left: 0px;
    font-size: 13px;
  }

  &:after {
    right: 0px;
    font-size: 20px;
  }
}

input.profileAccessibility__textSizeInput {
  margin: 0;
  padding: 0;
  max-width: 460px;
  width: calc(100% - 50px);
  height: 4px;
  background: none;
  border: none;
  outline: none;
  appearance: none;
}

$sliderthumb: 18px;

@mixin slider {
  position: relative;
  z-index: 3;
  appearance: none;
  width: $sliderthumb;
  height: $sliderthumb;
  background: $color-blue;
  border-radius: 50%;
  cursor: pointer;
}

input.profileAccessibility__textSizeInput::-webkit-slider-thumb {
  @include slider;
  -webkit-appearance: none;
}

input.profileAccessibility__textSizeInput::-moz-range-thumb {
  @include slider;
}

.profileAccessibility__textSizeOptions {
  pointer-events: none;
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 50px);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:before {
    content: "";
    display: flex;
    position: absolute;
    z-index: 0;
    height: 2px;
    background: $color-blue;
    width: 100%;
  }
}

.profileAccessibility__textSizeOption {
  content: "";
  position: relative;
  z-index: 1;
  display: flex;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background: $color-blue;

  span {
    @include label;
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -26px;
    padding: 0 0 0 5px;
    color: $color-blue;
    z-index: 3;
  }

  &--active {
    background: $color-blue;

    span {
      display: flex;
    }
  }
}

.profileAccessibility__note {
  @include label;
  max-width: 44ch;
  color: rgba($color-text, 0.7);
}
</style>
